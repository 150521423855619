// cartUtils.js

// Function to get the current cart from localStorage
export const getCart = () => {
    const cart = localStorage.getItem('cart');
    return cart ? JSON.parse(cart) : [];
  };
  
  // Function to add an item to the cart
  // In your cartUtils.js
export const addToCart = (product, packingSize, quantity = 1) => {
    const cart = getCart();
  
    // Check if product with the same packing size already exists in the cart
    const existingProductIndex = cart.findIndex(
      (item) => item.product.id === product.id && item.packingSize.id === packingSize.id
    );
  
    if (existingProductIndex !== -1) {
      // If the product already exists in the cart, update the quantity
      cart[existingProductIndex].quantity += quantity;
    } else {
      // Add a new product with the specified packing size and quantity to the cart
      cart.push({
        product,
        packingSize,
        quantity,
      });
    }
  
    // Store the updated cart in localStorage
    localStorage.setItem('cart', JSON.stringify(cart));
  };
  
  
  // Function to clear the cart (for example, after checkout)
  export const clearCart = () => {
    localStorage.removeItem('cart');
  };
  
  // Function to get the total number of items in the cart
export const getCartItemCount = () => {
    const cart = getCart();
    return cart.reduce((total, item) => total + item.quantity, 0);
  }