import axios from 'axios';
import React, { createContext, useContext, useReducer, useEffect } from 'react';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

const apiUnauth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Define action types
const actionTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER',
  SET_LOADING: 'SET_LOADING',
  LOGOUT: 'LOGOUT',
  SET_USER_TYPE: 'SET_USER_TYPE', // Added this line
};

// Auth reducer function
const authReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return { ...state, authToken: action.payload, isAuthenticated: !!action.payload };
    case actionTypes.SET_USER:
      return { ...state, user: action.payload };
    case actionTypes.SET_USER_TYPE:
      return { ...state, user_type: action.payload };
    case actionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case actionTypes.LOGOUT:
      return { ...state, authToken: null, isAuthenticated: false, user: null, user_type: null };
    default:
      return state;
  }
};

const initialState = {
  authToken: localStorage.getItem('authToken'),
  isAuthenticated: !!localStorage.getItem('authToken'),
  user: null,
  isLoading: true,
  user_type: null, // Added this line
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const fetchUserProfile = async () => {
    dispatch({ type: actionTypes.SET_LOADING, payload: true });
    try {
      const { data } = await apiAuth.get('/account/user/');
      dispatch({ type: actionTypes.SET_USER, payload: data });
    } catch (error) {
      console.error("Failed to fetch user profile", error);
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    const updateAxiosHeaders = (token) => {
      if (token) {
        apiAuth.defaults.headers.common['Authorization'] = `Token ${token}`;
        fetchUserProfile();
      } else {
        delete apiAuth.defaults.headers.common['Authorization'];
      }
    };

    updateAxiosHeaders(state.authToken);
  }, [state.authToken]);

  const login = async (token, user_type) => {
    try {
      // Store the token in localStorage
      localStorage.setItem('authToken', token);
      dispatch({ type: actionTypes.SET_TOKEN, payload: token });

      // Store user_type in state
      dispatch({ type: actionTypes.SET_USER_TYPE, payload: user_type });

      // Fetch the user's profile
      await fetchUserProfile();
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    dispatch({ type: actionTypes.LOGOUT });
  };

  return (
    <AuthContext.Provider value={{
      ...state,        // Provides authToken, isAuthenticated, user, isLoading, user_type
      login,           // Function to handle login
      logout,          // Function to handle logout
      apiAuth,         // Authenticated Axios instance
      apiUnauth,       // Unauthenticated Axios instance
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
