// CartPage.js
import React, { useState, useEffect } from 'react';
import { getCart } from './cartUtils';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setCartItems(getCart());
  }, []);

  const handleQuantityChange = (product, packingSize, change) => {
    const updatedCart = cartItems.map((item) => {
      if (
        item.product.id === product.id &&
        item.packingSize?.id === packingSize?.id
      ) {
        const newQuantity = item.quantity + change;
        return {
          ...item,
          quantity: newQuantity > 0 ? newQuantity : 1,
        };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleRemoveItem = (product, packingSize) => {
    const updatedCart = cartItems.filter(
      (item) =>
        !(
          item.product.id === product.id &&
          item.packingSize?.id === packingSize?.id
        )
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleProceedToCheckout = () => {
    if (isAuthenticated) {
      navigate('/checkout');
    } else {
      navigate('/login', { state: { from: '/checkout' } });
    }
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((total, item) => {
      const price =
        item.packingSize?.discount_price || item.packingSize?.price || 0;
      return total + price * item.quantity;
    }, 0);
  };

  // Calculate GST based on the product category's GST rate
  const calculateGST = () => {
    return cartItems.reduce((totalGST, item) => {
      const price =
        item.packingSize?.discount_price || item.packingSize?.price || 0;
      const gstRate = item.product.category?.gst_rate || 0;
      return totalGST + price * item.quantity * (gstRate / 100);
    }, 0);
  };

  const subtotal = calculateSubtotal();
  const gst = calculateGST();
  const total = subtotal + gst;

  if (cartItems.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <p className="text-center text-gray-500 mt-10 text-xl">
          Your cart is empty.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white shadow-lg rounded-lg my-10">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Your Cart
      </h1>
      <ul className="divide-y divide-gray-200">
        {cartItems.map((item, index) => (
          <li
            key={index}
            className="flex flex-col md:flex-row items-center md:items-stretch space-y-4 md:space-y-0 md:space-x-4 py-6"
          >
            {/* Product Thumbnail */}
            <img
              src={
                item.product.media[0]?.display_image_url ||
                'https://via.placeholder.com/150'
              }
              alt={item.product.name}
              className="w-full md:w-32 h-32 object-cover rounded-lg"
            />
            <div className="flex-1">
              {/* Product Details */}
              <h2 className="text-lg font-bold text-gray-800">
                {item.product.name}
              </h2>
              <p className="text-sm text-gray-600">
                Category: {item.product.category?.name || 'N/A'}
              </p>
              <p className="text-sm text-gray-600">
                Brand: {item.product.brand?.name || 'N/A'}
              </p>
              {/* Packing Size and Price */}
              {item.packingSize ? (
                <>
                  <p className="text-sm text-gray-600">
                    Packing Size:{' '}
                    <span className="font-semibold">
                      {item.packingSize.size}
                    </span>
                  </p>
                  <p className="text-sm text-gray-600">
                    MRP: ₹{item.packingSize.price} | Discount Price: ₹
                    {item.packingSize.discount_price}
                  </p>
                </>
              ) : (
                <p className="text-sm text-red-600">
                  Packing size not available.
                </p>
              )}
              {/* Delivery Info */}
              <p className="text-sm text-green-600 mt-2">
                Delivery in 5-7 days (Free Shipping)
              </p>
            </div>
            {/* Quantity Controls */}
            <div className="flex items-center space-x-2">
              <button
                onClick={() =>
                  handleQuantityChange(item.product, item.packingSize, -1)
                }
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-1 px-3 rounded-md focus:outline-none"
              >
                -
              </button>
              <span className="text-lg text-gray-800">{item.quantity}</span>
              <button
                onClick={() =>
                  handleQuantityChange(item.product, item.packingSize, 1)
                }
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-1 px-3 rounded-md focus:outline-none"
              >
                +
              </button>
            </div>
            {/* Remove Item Button */}
            <button
              onClick={() =>
                handleRemoveItem(item.product, item.packingSize)
              }
              className="text-red-500 hover:text-red-600 transition-colors"
            >
              <TrashIcon className="h-6 w-6" />
            </button>
            {/* Price */}
            <div className="text-right">
              {item.packingSize ? (
                <p className="font-semibold text-xl text-gray-800">
                  ₹
                  {(
                    (item.packingSize.discount_price ||
                      item.packingSize.price) * item.quantity
                  ).toFixed(2)}
                </p>
              ) : (
                <p className="font-semibold text-lg text-red-600">
                  Price not available
                </p>
              )}
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
        <p className="font-semibold">
          यदि आप खरीद के बाद 30 दिनों में से कम से कम 28 दिनों तक हमारे ऐप से एसएमएस भेजते हैं, तो आप हमारे पास से खरीदे गए बिना बिके उत्पाद को उसी कीमत पर वापस कर सकते हैं।
        </p>
      </div>

      {/* Order Summary */}
      <div className="mt-8 border-t pt-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Order Summary
        </h2>
        <div className="flex justify-between text-gray-700">
          <p>Subtotal</p>
          <p>₹{subtotal.toFixed(2)}</p>
        </div>
        <div className="flex justify-between text-gray-700">
          <p>Tax</p>
          <p>₹{gst.toFixed(2)}</p>
        </div>
        <div className="flex justify-between text-gray-700">
          <p>Shipping</p>
          <p>Free</p>
        </div>
        <div className="flex justify-between text-gray-800 font-bold text-xl mt-2">
          <p>Total</p>
          <p>₹{total.toFixed(2)}</p>
        </div>
        <div className="mt-6 text-right">
  <button
    onClick={handleProceedToCheckout}
    className="w-full md:w-auto bg-blue-500 text-white px-8 py-3 rounded-md hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400"
  >
    Proceed to Checkout
  </button>
</div>

      </div>
    </div>
  );
}

export default Cart;
