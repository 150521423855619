import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';

const VOrderPage = () => {
    const { apiAuth, isAuthenticated, isLoading } = useAuth();    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState('');
    
    useEffect(() => {
        const fetchOrders = async () => {
          if (isLoading || !isAuthenticated) return;  // Ensure user is authenticated and loading is complete
          try {
            const response = await apiAuth.get('/orders/orders/');  
            setOrders(response.data.results);  // Fetch the orders from the "results" field
        } catch (error) {
            console.error("Error fetching orders", error);
          }
        };
        fetchOrders();
      }, [apiAuth, isAuthenticated, isLoading]);

      const handleStatusChange = async (orderId, newStatus) => {
        try {
            const response = await apiAuth.post(`/orders/orders/${orderId}/update_status/`, { status: newStatus });
            setOrders(orders.map(order => order.id === orderId ? { ...order, status: newStatus } : order));
            console.log(response.data);
        } catch (error) {
            console.error("Error updating status", error.response?.data || error.message);
            alert(`Failed to update status: ${error.response?.data?.error || error.message}`);
        }
    };

    const handleTrackingUpdate = async (orderId, trackingNumber) => {
        try {
            await apiAuth.post(`/orders/track//${orderId}/`, { tracking_number: trackingNumber });
            setOrders(orders.map(order => order.id === orderId ? { ...order, tracking_number: trackingNumber } : order));
        } catch (error) {
            console.error("Error updating tracking number", error);
        }
    };

    const handleNotification = async (orderId) => {
        try {
            await apiAuth.post(`/orders/orders/${orderId}/send_notification/`);
            alert('Notification sent successfully');
        } catch (error) {
            console.error("Error sending notification", error);
        }
    };

    const downloadInvoice = async (orderId) => {
        try {
            const response = await apiAuth.get(`/api/orders/${orderId}/invoice/`, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${orderId}.pdf`); // Name of the downloaded file
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading invoice:', error);
        }
    };

    const showShippingAddress = (order) => {
        const { shipping_address } = order;
        if (!shipping_address) return "No shipping address available";
        return `${shipping_address.name}, ${shipping_address.street}, ${shipping_address.city}, ${shipping_address.state}, ${shipping_address.postal_code}, ${shipping_address.country}`;
    };

    const handleCheckboxToggle = (orderId, isChecked) => {
        const newStatus = isChecked ? 'shipped' : 'processing';
        handleStatusChange(orderId, newStatus);
    };

    return (
        <div className="container mx-auto p-6 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-center">Vendor Order Management</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-800 text-white">
                        <tr>
                            <th className="py-3 px-6 text-left">Order ID</th>
                            <th className="py-3 px-6 text-left">Retailer</th>
                            <th className="py-3 px-6 text-left">Total</th>
                            <th className="py-3 px-6 text-left">Order Items</th>
                            <th className="py-3 px-6 text-left">Shipping Address</th>
                            <th className="py-3 px-6 text-left">Status</th>
                            <th className="py-3 px-6 text-center">Mark as Shipped</th>

                            <th className="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {orders.map(order => (
                        <tr key={order.id} className="border-b hover:bg-gray-50">
                        <td className="py-4 px-6">{order.id}</td>
                        <td className="py-4 px-6">{order.user.shop_name }</td>
                        <td className="py-4 px-6">{order.total}</td>
                        <td className="py-4 px-6">
                                {order.order_items.map(item => (
                                    <div key={item.product.id}>
                                        <span className="font-semibold">{item.product.name}</span> - Quantity: {item.quantity}
                                    </div>
                                ))}
                            </td>
                        <td className="py-4 px-6">{showShippingAddress(order)}</td>
                        <td className="py-4 px-6">{order.status}</td>
                        <td className="py-4 px-6 text-center">
                                    <input
                                        type="checkbox"
                                        checked={order.status === 'shipped'}
                                        onChange={(e) => handleCheckboxToggle(order.id, e.target.checked)}
                                        className="mr-2 leading-tight"
                                    />
                                </td>
                        <td className="py-4 px-6 text-center space-y-2">
                                <button 
                                    onClick={() => handleTrackingUpdate(order.id, prompt('Enter tracking number:'))} 
                                    className="w-full bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                >
                                    Add Tracking
                                </button>
                                <button 
                                    onClick={() => handleNotification(order.id)} 
                                    className="w-full bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                                >
                                    Send Notification
                                </button>
                                <button 
                                    onClick={() => downloadInvoice(order.id)} 
                                    className="w-full bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Download Invoice
                                </button>
                            </td>
                        </tr>
                    ))}

                    </tbody>

                </table>
            </div>
        </div>
    );
};

export default VOrderPage;
