import { useEffect } from 'react';

function useFacebookPixel() {
  useEffect(() => {
    if (window.fbq) return; // Only load fbq if it's not already loaded

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    script.async = true;
    script.onload = () => {
      if (typeof window.fbq === 'function') {
        window.fbq('init', '1026091325706446'); // Replace with your Pixel ID
        window.fbq('track', 'PageView');
      } else {
        console.error('Facebook Pixel script failed to load.');
      }
    };
    document.body.appendChild(script);

    return () => {
      const fbPixelScript = document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]');
      fbPixelScript && fbPixelScript.remove();
      window.fbq = undefined; // Optionally clean up the fbq function from window
    };
  }, []);
}

export default useFacebookPixel;

