import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { addToCart, getCartItemCount } from './cartUtils';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

function CategoryPage() {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [crops, setCrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedPackingSizes, setSelectedPackingSizes] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0);
  const [quantities, setQuantities] = useState({});
  const [filters, setFilters] = useState({ category: '', brand: '', crop: '' });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/category/${categoryId}/products/`);
        const productsData = response.data.results || response.data;
        setProducts(productsData);
        setLoading(false);

        // Extract categories, brands, and crops from products
        const categories = [...new Set(productsData.map(product => product.category.name))];
        const brands = [...new Set(productsData.map(product => product.brand.name))];
        const crops = [...new Set(productsData.flatMap(product => product.recommended_for_names))];

        setCategories(categories);
        setBrands(brands);
        setCrops(crops);

        // Initialize default packing size and quantities for each product
        const defaultPackingSizes = {};
        const defaultQuantities = {};
        productsData.forEach(product => {
          if (product.packing_sizes.length > 0) {
            defaultPackingSizes[product.id] = product.packing_sizes[0].id;
            defaultQuantities[product.id] = 1;
          }
        });
        setSelectedPackingSizes(defaultPackingSizes);
        setQuantities(defaultQuantities);
      } catch (err) {
        console.error(err);
        setError('Failed to fetch products.');
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoryId]);

  useEffect(() => {
    setCartItemCount(getCartItemCount());
  }, []);

  const handleQuantityChange = (productId, change) => {
    setQuantities(prevState => ({
      ...prevState,
      [productId]: Math.max(1, (prevState[productId] || 1) + change),
    }));
  };

  const handlePackingSizeChange = (event, productId, packingSizeId) => {
    event.stopPropagation();
    setSelectedPackingSizes(prevState => ({
      ...prevState,
      [productId]: packingSizeId,
    }));
  };

  const handleAddToCart = (product) => {
    const selectedPackingSize = product.packing_sizes.find(
      packingSize => packingSize.id === selectedPackingSizes[product.id]
    );
    addToCart(product, selectedPackingSize, quantities[product.id]);
    alert(`Added ${quantities[product.id]} x ${product.name} (${selectedPackingSize.size}) to cart!`);
    setCartItemCount(getCartItemCount());
  };

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterType]: value }));
  };

  const filteredProducts = products.filter(product => {
    return (
      (filters.category === '' || product.category.name === filters.category) &&
      (filters.brand === '' || product.brand.name === filters.brand) &&
      (filters.crop === '' || product.recommended_for_names.includes(filters.crop))
    );
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="bg-gray-100 min-h-screen p-4">
      <div className="max-w-7xl mx-auto">
        {/* Cart Icon with Count */}
        <div className="fixed top-4 right-4">
          <Link to="/cart">
            <div className="relative">
              <ShoppingCartIcon className="text-3xl text-gray-700" />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                  {cartItemCount}
                </span>
              )}
            </div>
          </Link>
        </div>

        {/* Filters */}
        <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Filter by Category</label>
            <select
              value={filters.category}
              onChange={(e) => handleFilterChange('category', e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">All Categories</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Filter by Brand</label>
            <select
              value={filters.brand}
              onChange={(e) => handleFilterChange('brand', e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">All Brands</option>
              {brands.map((brand, index) => (
                <option key={index} value={brand}>
                  {brand}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Filter by Crop</label>
            <select
              value={filters.crop}
              onChange={(e) => handleFilterChange('crop', e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">All Crops</option>
              {crops.map((crop, index) => (
                <option key={index} value={crop}>
                  {crop}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Product Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
              onClick={() => handleProductClick(product.id)}
            >
              <img
                src={product.media[0]?.display_image_url || 'https://via.placeholder.com/150'}
                alt={product.name}
                className="w-full h-48 object-cover mb-4 rounded-lg"
              />
              <h2 className="text-xl font-bold mb-1">{product.name}</h2>
              <p className="text-sm text-gray-600 mb-1">Brand: {product.brand.name}</p>
              <p className="text-sm text-gray-600 mb-1">Category: {product.category.name}</p>
              <p className="text-sm text-gray-600 mb-1">Chemicals: {product.chemical_names.join(', ')}</p>
              <p className="text-sm text-gray-600 mb-1">Recommended for: {product.recommended_for_names.join(', ')}</p>

              {product.packing_sizes.length > 0 && (
                <div className="mb-4">
                  <label
                    htmlFor={`packing-size-${product.id}`}
                    className="block mb-1 text-sm font-medium text-gray-700"
                  >
                    Select Packing Size
                  </label>
                  <select
                    id={`packing-size-${product.id}`}
                    value={selectedPackingSizes[product.id]}
                    onChange={(e) => handlePackingSizeChange(e, product.id, parseInt(e.target.value))}
                    onClick={(e) => e.stopPropagation()}
                    className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    {product.packing_sizes.map((packingSize) => (
                      <option key={packingSize.id} value={packingSize.id}>
                        {packingSize.size} - ₹{packingSize.discount_price || packingSize.price}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {product.packing_sizes.length > 0 && (
                <p className="text-green-600 font-bold mb-2">
                  MRP: ₹
                  {product.packing_sizes.find(
                    packingSize => packingSize.id === selectedPackingSizes[product.id]
                  )?.price || 'N/A'}
                  , Discount: ₹
                  {product.packing_sizes.find(
                    packingSize => packingSize.id === selectedPackingSizes[product.id]
                  )?.discount_price || 'N/A'}
                </p>
              )}

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleQuantityChange(product.id, -1);
                    }}
                    className="bg-gray-200 px-2 py-1 rounded-l-md hover:bg-gray-300"
                  >
                    -
                  </button>
                  <span className="px-4 py-1 bg-white border-t border-b border-gray-200">
                    {quantities[product.id] || 1}
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleQuantityChange(product.id, 1);
                    }}
                    className="bg-gray-200 px-2 py-1 rounded-r-md hover:bg-gray-300"
                  >
                    +
                  </button>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(product);
                  }}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CategoryPage;
