import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const VDashboard = () => {
  const [salesData, setSalesData] = useState({ day: 0, week: 0, month: 0 });
  const [ordersSummary, setOrdersSummary] = useState({ pending: 0, processing: 0, shipped: 0, delivered: 0 });
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [lowStockAlerts, setLowStockAlerts] = useState([]);
  const [revenueData, setRevenueData] = useState({ revenue: 0, profit: 0, outstandingPayments: 0 });
  const [notifications, setNotifications] = useState([]);
  const [salesTrendData, setSalesTrendData] = useState({});

  useEffect(() => {
    // Fetch sales data
    axios.get('/api/sales-overview/')
      .then(response => setSalesData(response.data))
      .catch(error => console.error('Error fetching sales data:', error));

    // Fetch orders summary
    axios.get('/api/orders-summary/')
      .then(response => setOrdersSummary(response.data))
      .catch(error => console.error('Error fetching orders summary:', error));

    // Fetch top-selling products
    axios.get('/api/top-selling-products/')
      .then(response => setTopSellingProducts(response.data))
      .catch(error => console.error('Error fetching top-selling products:', error));

    // Fetch low stock alerts
    axios.get('/api/low-stock-alerts/')
      .then(response => setLowStockAlerts(response.data))
      .catch(error => console.error('Error fetching low stock alerts:', error));

    // Fetch revenue and profit insights
    axios.get('/api/revenue-insights/')
      .then(response => setRevenueData(response.data))
      .catch(error => console.error('Error fetching revenue data:', error));

    // Fetch notifications
    axios.get('/api/notifications/')
      .then(response => setNotifications(response.data))
      .catch(error => console.error('Error fetching notifications:', error));

    // Fetch sales trend data for chart
    axios.get('/api/sales-trend/')
      .then(response => setSalesTrendData(response.data))
      .catch(error => console.error('Error fetching sales trend data:', error));
  }, []);

  const salesTrendChartData = {
    labels: salesTrendData.labels || [],
    datasets: [
      {
        label: 'Sales',
        data: salesTrendData.sales || [],
        borderColor: '#4F46E5',
        backgroundColor: 'rgba(79, 70, 229, 0.2)',
        fill: true,
      },
    ],
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Vendor Dashboard</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Sales Overview */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Sales Overview</h2>
          <p className="text-gray-700 mb-2">Total Sales Today: <span className="font-bold">₹{salesData.day}</span></p>
          <p className="text-gray-700 mb-2">Total Sales This Week: <span className="font-bold">₹{salesData.week}</span></p>
          <p className="text-gray-700 mb-4">Total Sales This Month: <span className="font-bold">₹{salesData.month}</span></p>
          <Line data={salesTrendChartData} className="mt-4"/>
        </div>

        {/* Orders Summary */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Orders Summary</h2>
          <p className="text-gray-700 mb-2">Pending: <span className="font-bold">{ordersSummary.pending}</span></p>
          <p className="text-gray-700 mb-2">Processing: <span className="font-bold">{ordersSummary.processing}</span></p>
          <p className="text-gray-700 mb-2">Shipped: <span className="font-bold">{ordersSummary.shipped}</span></p>
          <p className="text-gray-700">Delivered: <span className="font-bold">{ordersSummary.delivered}</span></p>
        </div>

        {/* Top Selling Products */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Top Selling Products</h2>
          <ul className="list-disc list-inside text-gray-700">
            {topSellingProducts.map(product => (
              <li key={product.id}>
                {product.name} - <span className="font-bold">{product.sold}</span> sold
              </li>
            ))}
          </ul>
        </div>

        {/* Low Stock Alerts */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Low Stock Alerts</h2>
          <ul className="list-disc list-inside text-gray-700">
            {lowStockAlerts.map(product => (
              <li key={product.id}>
                {product.name} - Only <span className="font-bold">{product.stock}</span> left in stock
              </li>
            ))}
          </ul>
        </div>

        {/* Revenue and Profit Insights */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Revenue and Profit</h2>
          <p className="text-gray-700 mb-2">Total Revenue: <span className="font-bold">₹{revenueData.revenue}</span></p>
          <p className="text-gray-700 mb-2">Total Profit: <span className="font-bold">₹{revenueData.profit}</span></p>
          <p className="text-gray-700">Outstanding Payments: <span className="font-bold">₹{revenueData.outstandingPayments}</span></p>
        </div>

        {/* Notifications */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Notifications</h2>
          <ul className="list-disc list-inside text-gray-700">
            {notifications.map((notification, index) => (
              <li key={index}>{notification.message}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VDashboard;
