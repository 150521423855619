// components/common/ProductCard.js
import React from 'react';

const ProductCard = ({
  product,
  quantities = {},
  handleAddToCart = () => {},
  handleQuantityChange = () => {},
  handlePackingSizeChange = () => {},
  selectedPackingSizes = {},
  onProductClick = () => {},
}) => {

  // For selectedPackingSizes
const selectedPackingSize = selectedPackingSizes
? selectedPackingSizes[product.id]
: null;

// For quantities
const quantity = quantities ? quantities[product.id] || 1 : 1;

  return (
    <div
      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-col"
      onClick={() => onProductClick(product.id)}
    >
      {/* Square Image */}
      <div className="w-full aspect-w-1 aspect-h-1">
        <img
          src={
            product.media && product.media.length > 0
              ? product.media[0].display_image_url
              : 'https://via.placeholder.com/150'
          }
          alt={product.name}
          className="w-full h-full object-cover rounded-t-lg"
        />
      </div>

      {/* Product Info */}
      <div className="p-4 flex-grow flex flex-col">
        <h2 className="text-xl font-bold mb-1">{product.name}</h2>
        <p className="text-sm text-gray-600 mb-1">
          Brand: {product.brand.name}
        </p>
        <p className="text-sm text-gray-600 mb-1">
          Category: {product.category.name}
        </p>
        <p className="text-sm text-gray-600 mb-1">Chemicals: {product.chemical_names.join(', ')}</p>

        <p className="text-sm text-gray-600 mb-1">
          Recommended for: {product.recommended_for_names.join(', ')}
        </p>

        {product.packing_sizes.length > 0 && (
          <div className="mb-4">
            <label
              htmlFor={`packing-size-${product.id}`}
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Select Packing Size
            </label>
            <select
              id={`packing-size-${product.id}`}
              value={selectedPackingSize}
              onChange={(e) =>
                handlePackingSizeChange(
                  e,
                  product.id,
                  parseInt(e.target.value)
                )
              }
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              {product.packing_sizes.map((packingSize) => (
                <option key={packingSize.id} value={packingSize.id}>
                  {packingSize.size} - ₹
                  {packingSize.discount_price || packingSize.price}
                </option>
              ))}
            </select>
          </div>
        )}

        {product.packing_sizes.length > 0 && (
          <p className="text-green-600 font-bold mb-2">
            MRP: ₹
            {
              product.packing_sizes.find(
                (ps) => ps.id === selectedPackingSizes[product.id]
              )?.price || 'N/A'
            }
            , Discount Price: ₹
            {
              product.packing_sizes.find(
                (ps) => ps.id === selectedPackingSizes[product.id]
              )?.discount_price || 'N/A'
            }
          </p>
        )}

        {/* Adjusted Layout */}
        <div className="mt-auto">
          <div className="flex flex-col xs:flex-row items-center xs:justify-between space-y-2 xs:space-y-0 xs:space-x-2">
            {/* Quantity Selector */}
            <div className="flex items-center w-full xs:w-auto">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleQuantityChange(product.id, -1);
                }}
                className="bg-gray-200 px-2 py-2 rounded-l-md hover:bg-gray-300"
              >
                -
              </button>
              <span className="px-4 py-2 bg-white border-t border-b border-gray-200">
                {quantities[product.id] || 1}
              </span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleQuantityChange(product.id, 1);
                }}
                className="bg-gray-200 px-2 py-2 rounded-r-md hover:bg-gray-300"
              >
                +
              </button>
            </div>
            {/* Add to Cart Button */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleAddToCart(product);
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-full xs:w-auto"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
