// EcomPage.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { addToCart, getCartItemCount } from './cartUtils';
import { useAuth } from '../context/AuthContext';
import SimpleProductCard from './common/SimpleProductCard';
import ProductCard from './common/ProductCard';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import debounce from 'lodash.debounce';

// Helper Components
const SectionTitle = ({ title }) => (
  <h2 className="text-3xl font-bold mb-8 text-gray-800 text-center">{title}</h2>
);

const EcomPage = () => {
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [selectedPackingSizes, setSelectedPackingSizes] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [banners, setBanners] = useState([]);

  // Handle adding a product to the cart
  const handleAddToCart = (product) => {
    const selectedPackingSize = product.packing_sizes.find(
      (packingSize) => packingSize.id === selectedPackingSizes[product.id]
    );
    const quantity = quantities[product.id] || 1;
    addToCart(product, selectedPackingSize, quantity);

    setCartItemCount(getCartItemCount()); // Update cart item count
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSearchResults([]);
        setSearchLoading(false);
        return;
      }

      setSearchLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        const config = token
          ? { headers: { Authorization: `Token ${token}` } }
          : {};
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/search/`,
          {
            params: { query },
            ...config,
          }
        );
        setSearchResults(response.data.results || []);
      } catch (error) {
        console.error('Failed to search products:', error);
      }
      setSearchLoading(false);
    }, 500), // Adjust the delay as needed (500ms)
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  // Handle quantity changes
  const handleQuantityChange = (productId, change) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max(1, (prevQuantities[productId] || 1) + change),
    }));
  };

  // Handle packing size changes
  const handlePackingSizeChange = (e, productId, packingSizeId) => {
    e.stopPropagation();
    setSelectedPackingSizes((prevSelected) => ({
      ...prevSelected,
      [productId]: packingSizeId,
    }));
  };

  // Navigate to the product details page
  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        const token = localStorage.getItem('authToken');
        const config = token
          ? { headers: { Authorization: `Token ${token}` } }
          : {};
        setLoading(true);

        try {
          // Fetch best-selling products, categories, and brands
          const [bestSellingRes, categoryRes, brandRes] = await Promise.all([
            axios.get(`${process.env.REACT_APP_API_URL}/products/bestsellers/`, config),
            axios.get(`${process.env.REACT_APP_API_URL}/products/categories/`, config),
            axios.get(`${process.env.REACT_APP_API_URL}/products/brands/`, config),
          ]);

          setBestSellingProducts(bestSellingRes.data.results || []);
          setCategories(categoryRes.data || []);
          setBrands(brandRes.data || []);

          // Initialize selected packing sizes
          const initialPackingSizes = {};
          const initialQuantities = {};

          bestSellingRes.data.results.forEach((product) => {
            if (product.packing_sizes && product.packing_sizes.length > 0) {
              initialPackingSizes[product.id] = product.packing_sizes[0].id;
            }
          });
          setSelectedPackingSizes(initialPackingSizes);
          setQuantities(initialQuantities);
        } catch (error) {
          console.error('Failed to load data:', error);
        }

        // Fetch recommended products
        try {
          const recommendedRes = await axios.get(
            `${process.env.REACT_APP_API_URL}/products/recommended/`,
            config
          );
          setRecommendedProducts(recommendedRes.data.results || []);

          // Update selected packing sizes
          setSelectedPackingSizes((prevSelected) => {
            const newSelected = { ...prevSelected };
            if (recommendedRes.data && recommendedRes.data.results) {
              recommendedRes.data.results.forEach((product) => {
                if (product.packing_sizes && product.packing_sizes.length > 0) {
                  newSelected[product.id] = product.packing_sizes[0].id;
                }
              });
            }
            return newSelected;
          });
        } catch (error) {
          console.error('Failed to load recommended products:', error);
        }

        // Fetch banners
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/welcome/banners/`);
          const baseURL = process.env.REACT_APP_API_URL;
          const bannersWithFullImageURL = response.data.map((banner) => ({
            ...banner,
            image: `${baseURL}${banner.image}`,
          }));
          setBanners(response.data);
        } catch (error) {
          console.error('Failed to load banners:', error);
        }

        setLoading(false);
      };

      fetchData();
    } else {
      setLoading(false); // Stop loading if not authenticated
    }
  }, [isAuthenticated]);

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-gray-500 text-lg">Loading...</div>
      </div>
    );

  if (!isAuthenticated) {
    // Render login and registration buttons
    return (
      <section className="bg-gray-50 min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-6">Welcome to Krshak</h1>
          <p className="mb-6 text-lg">Please log in or register to access our products.</p>
          <div className="space-x-4">
            <Link
              to="/login"
              state={{ from: location }}
              className="bg-blue-500 text-white rounded-md px-6 py-2 hover:bg-blue-600"
            >
              Login
            </Link>
            <Link
              to="/register"
              state={{ from: location }}
              className="bg-green-500 text-white rounded-md px-6 py-2 hover:bg-green-600"
            >
              Register
            </Link>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="bg-gray-50 min-h-screen">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-6 py-4 flex items-center justify-between">
          {/* Logo */}
          <Link to="/ecom" className="flex items-center text-2xl font-bold text-gray-800">
            Krshak
          </Link>

          {/* Search Bar */}
          <div className="flex-1 mx-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search products by name, technical, brand, or category..."
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Cart Icon with Count */}
          <div className="relative">
            <Link to="/cart">
              <ShoppingCartIcon className="h-8 w-8 text-gray-700" />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                  {cartItemCount}
                </span>
              )}
            </Link>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="container mx-auto mt-6">
        {banners.length > 0 ? (
          <Carousel
            showThumbs={false}
            autoPlay
            infiniteLoop
            showStatus={false}
            className="rounded-lg overflow-hidden shadow-lg"
          >
            {banners.map((banner) => (
              <div key={banner.id}>
                {banner.link ? (
                  <a href={banner.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={banner.image}
                      alt={banner.title || `Banner ${banner.id}`}
                      className="object-cover w-full h-64 md:h-96"
                    />
                  </a>
                ) : (
                  <img
                    src={banner.image}
                    alt={banner.title || `Banner ${banner.id}`}
                    className="object-cover w-full h-64 md:h-96"
                  />
                )}
              </div>
            ))}
          </Carousel>
        ) : (
          <div className="bg-gray-200 h-64 md:h-96 flex items-center justify-center">
            <p className="text-gray-500">No banners available.</p>
          </div>
        )}
      </div>

      <div className="container mx-auto px-6 py-12">
        {/* Search Loading Indicator */}
        {searchLoading && (
          <div className="flex items-center justify-center">
            <div className="text-gray-500 text-lg">Searching...</div>
          </div>
        )}

        {/* Search Results */}
        {searchResults.length > 0 && (
          <>
            <SectionTitle title={`Search Results for "${searchQuery}"`} />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {searchResults.map((product) => (
                <SimpleProductCard
                  key={product.id}
                  product={product}
                  onProductClick={handleProductClick}
                />
              ))}
            </div>
          </>
        )}

        {/* No Search Results */}
        {searchResults.length === 0 && searchQuery && !searchLoading && (
          <p className="text-center text-gray-600 mt-4">
            No products found for "{searchQuery}".
          </p>
        )}

        {/* Recommended Products */}
        {recommendedProducts.length > 0 && !searchQuery && (
          <>
            <SectionTitle title="Recommended Products" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {recommendedProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  quantities={quantities}
                  handleAddToCart={handleAddToCart}
                  handleQuantityChange={handleQuantityChange}
                  handlePackingSizeChange={handlePackingSizeChange}
                  selectedPackingSizes={selectedPackingSizes}
                  onProductClick={handleProductClick}
                />
              ))}
            </div>
          </>
        )}

        {/* Best Selling Products */}
        {bestSellingProducts.length > 0 && !searchQuery && (
          <>
            <SectionTitle title="Best Sellers" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {bestSellingProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  quantities={quantities}
                  handleAddToCart={handleAddToCart}
                  handleQuantityChange={handleQuantityChange}
                  handlePackingSizeChange={handlePackingSizeChange}
                  selectedPackingSizes={selectedPackingSizes}
                  onProductClick={handleProductClick}
                />
              ))}
            </div>
          </>
        )}

        {/* Categories */}
        {categories.length > 0 && !searchQuery && (
          <>
            <SectionTitle title="Categories" />
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 text-center p-4 flex flex-col items-center cursor-pointer"
                  onClick={() => navigate(`/category/${category.id}/products`)}
                >
                  <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                    <span className="text-2xl font-bold text-blue-500">
                      {category.name.charAt(0)}
                    </span>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-800">{category.name}</h3>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Brands */}
        {brands.length > 0 && !searchQuery && (
          <>
            <SectionTitle title="Brands" />
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8">
              {brands.map((brand) => (
                <div
                  key={brand.id}
                  className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 text-center p-4 flex flex-col items-center cursor-pointer"
                  onClick={() => navigate(`/brand/${brand.id}/products`)}
                >
                  {brand.logo ? (
                    <img
                      src={brand.logo}
                      alt={brand.name}
                      className="w-24 h-24 object-contain mb-4"
                    />
                  ) : (
                    <div className="w-24 h-24 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                      <span className="text-3xl font-bold text-blue-500">
                        {brand.name.charAt(0)}
                      </span>
                    </div>
                  )}
                  <h3 className="text-lg font-semibold text-gray-800">{brand.name}</h3>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default EcomPage;
