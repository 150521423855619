// PaymentCallback.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function PaymentCallback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Extract query parameters from URL
    const params = new URLSearchParams(location.search);
    const status = params.get('status');

    if (status === 'success') {
      // Clear cart
      localStorage.removeItem('cart');
      // Redirect to Order Summary Page
      navigate('/order-summary');
    } else {
      // Redirect back to Checkout Page
      navigate('/checkout', { state: { paymentFailed: true } });
    }
  }, [location, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <p className="text-xl text-gray-700">Processing payment...</p>
    </div>
  );
}

export default PaymentCallback;
