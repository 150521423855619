import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { BrowserRouter , Routes, Route, Link, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import useFacebookPixel from './components/useFacebookPixel'; // Adjust the path based on where you save the hook
import './App.css';
import { useTransition } from 'react';
import VendorRegisterForm from './components/RegisterFormVendor';
import VDashboard from './components/VDashboard';
import VProductPage from './components/VProductPage';
import VOrderPage from './components/VOrderPage';
import ProductDetail from './components/ProductDetail';
import VendorPortfolio from './components/VendorPortfolio';
import Cart from './components/Cart';
import EcomPage from './components/EcomPage';
import BrandPage from './components/BrandPage';
import CategoryPage from './components/CategoryPage';
import CropPage from './components/CropPage';
import OrderSummaryPage from './components/OrderSummaryPage';
import PaymentCallback from './components/common/PaymentCallback';
import CheckoutPage from './components/CheckoutPage';

// Lazy loading components
const FarmerList = React.lazy(() => import('./components/FarmerList'));
const AddFarmerForm = React.lazy(() => import('./components/AddFarmerForm'));
const AddFarmForm = React.lazy(() => import('./components/AddFarmForm'));
const LoginForm = React.lazy(() => import('./components/LoginForm'));
const RegisterForm = React.lazy(() => import('./components/RegisterForm'));
const FarmerDetail = React.lazy(() => import('./components/FarmerDetail'));
const Home = React.lazy(() => import('./components/Home'));
const Header = React.lazy(() => import('./components/common/Header'));
const Footer = React.lazy(() => import('./components/common/Footer'));
const RetailerPage = React.lazy(() => import('./components/RetailerPage'));
const RMDashboard = React.lazy(() => import('./components/RMDashboard'));
const CallPlanPage = React.lazy(() => import('./components/CallPlanPage'));


function PrivacyPolicy(){
  const navigate = useNavigate();
  const lastUpdated = new Date().toLocaleDateString();

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow">
  <h1 className="text-2xl font-bold text-gray-800 mb-4">Privacy Policy for Krshak</h1>
  <p className="text-gray-600">Last updated: {lastUpdated}</p>
  
  <section className="mt-6">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">What Information We Collect</h2>
    <p className="text-gray-600 mb-4">To provide and improve our service to you, we collect certain information during the registration process and as you use our app:</p>
    <ul className="list-disc pl-8 text-gray-600 mb-4">
      <li><strong>Agri Input Retailer Information:</strong> At registration, we ask for your shop name, mobile number, and pincode. This information helps us tailor our services to your location and business needs.</li>
      <li><strong>Farmer Information:</strong> You have the option to update the app with farmer names, phone numbers, their crops, and crop ages. We utilize this data to facilitate communication with farmers on your behalf, enhancing our service's value to both you and the farmers you serve.</li>
    </ul>
  </section>
  
  <section className="mt-4">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">How We Use Your Information</h2>
    <p className="text-gray-600 mb-4">The information you provide is crucial for us to offer personalized services. Specifically, we use this data to:</p>
    <ul className="list-disc pl-8 text-gray-600">
      <li>Send targeted communications to farmers, based on the information you provide about their crops and needs.</li>
      <li>Enhance our app's functionality and tailor it more closely to your business and the farmers you support.</li>
    </ul>
  </section>
  
  <section className="mt-4">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">Your Data Protection</h2>
    <p className="text-gray-600">We take the protection of your data seriously. Only authorized personnel have access to your information, and we employ robust security measures to prevent unauthorized access or disclosure.</p>
  </section>
  
  <section className="mt-4">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">Contact Us</h2>
    <p className="text-gray-600">If you have any questions or concerns about our Privacy Policy or the data we hold on you, please contact us at team@krshak.com. Your trust in our service is our top priority.</p>
  </section>
</div>

  );
};

function DeleteAccount() {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-2xl font-semibold mb-4">Delete Your Krshak Account</h1>
      <p className="mb-4">
        We're sad to see you go! If you decide to delete your account, please follow the steps below. Remember, deleting your account is irreversible.
      </p>
      <ul className="list-disc ml-8 mb-6 space-y-2">
        <li>
          <strong>Navigate to Settings:</strong> Open the app and go to the settings page.
        </li>
        <li>
          <strong>Select 'Delete Account':</strong> Find the option to delete your account. This action may require you to log in again for verification purposes.
        </li>
        <li>
          <strong>Confirm Deletion:</strong> You'll be asked to confirm your account deletion. Please read the information carefully, as this action cannot be undone.
        </li>
      </ul>
      <h2 className="text-xl font-semibold mb-2">What Happens When I Delete My Account?</h2>
      <p className="mb-6">
        You will lose access to your account immediately. All your data will be permanently deleted from our servers within 30 days. You may not be able to recover any data or information from your account in the future.
      </p>
      <div className="mb-8">
        <p className="font-semibold">Need Help?</p>
        <p>
          If you're experiencing issues or have questions about deleting your account, please contact our support team at <a href="mailto:team@krshak.com" className="text-blue-600 underline">team@krshak.com</a> for assistance.
        </p>
      </div>

      {/* Terms and Conditions */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Terms & Conditions</h2>
        <div className="space-y-4 text-justify">
          <p>
            <strong>A.</strong> This document is an electronic record in terms of Information Technology Act, 2000 and rules
            there under as applicable and the amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This electronic record is
            generated by a computer system and does not require any physical or digital signatures.
          </p>
          <p>
            <strong>B.</strong> This document is published in accordance with the provisions of Rule 3 (1) of the Information
            Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
            regulations, privacy policy and Terms of Use for access or usage of domain name{' '}
            <a href="https://krshak.com" className="text-blue-600 underline">krshak.com</a> (“Website”), including the related mobile site and mobile application (hereinafter referred
            to as “Platform”).
          </p>
          <p>
            <strong>C.</strong> The Platform is owned by MATRABHUMI TECHNOLOGY PRIVATE LIMITED, a company incorporated under the
            Companies Act, 1956 with its registered office at:
          </p>
          <ul className="list-disc ml-8">
            <li>Floor No.: Ground Floor</li>
            <li>Building No./Flat No.: H.no. 411</li>
            <li>Road/Street: Siwana, Kahanaur</li>
            <li>Nearby Landmark: near primary health centre</li>
            <li>Locality/Sub Locality: Beri</li>
            <li>City/Town/Village: Majra Jhajjar</li>
            <li>District: Jhajjar</li>
            <li>State: Haryana</li>
            <li>PIN Code: 124202</li>
          </ul>
          <p>
            (hereinafter referred to as "Platform Owner", “we”, “us”, “our”).
          </p>
          <p>
            <strong>D.</strong> Your use of the Platform and services and tools are governed by the following terms and
            conditions ("Terms of Use") as applicable to the Platform including the applicable policies which
            are incorporated herein by way of reference. If You transact on the Platform, You shall be subject
            to the policies that are applicable to the Platform for such transaction. By mere use of the
            Platform, You shall be contracting with the Platform Owner and these terms and conditions
            including the policies constitute Your binding obligations, with Platform Owner. These Terms of
            Use relate to your use of our website, goods (as applicable) or services (as applicable)
            (collectively, “Services”). Any terms and conditions proposed by You which are in addition to or
            which conflict with these Terms of Use are expressly rejected by the Platform Owner and shall be
            of no force or effect. These Terms of Use can be modified at any time without assigning any
            reason. It is your responsibility to periodically review these Terms of Use to stay informed of
            updates.
          </p>
          <p>
            <strong>E.</strong> For the purpose of these Terms of Use, wherever the context so requires "you", “your” or "user"
            shall mean any natural or legal person who has agreed to become a user/buyer on the Platform.
          </p>
          <p className="font-semibold">G. The use of Platform and/or availing of our Services is subject to the following Terms of Use:</p>
          <ol className="list-decimal ml-8 space-y-2">
            <li>
              To access and use the Services, you agree to provide true, accurate and complete information to
              us during and after registration, and you shall be responsible for all acts done through the use of
              your registered account on the Platform.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
              timeliness, performance, completeness or suitability of the information and materials offered on
              this website or through the Services, for any specific purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors and we expressly exclude liability
              for any such inaccuracies or errors to the fullest extent permitted by law.
            </li>
            <li>
              Your use of our Services and the Platform is solely and entirely at your own risk and discretion for
              which we shall not be liable to you in any manner. You are required to independently assess and
              ensure that the Services meet your requirements.
            </li>
            <li>
              The contents of the Platform and the Services are proprietary to us and are licensed to us. You
              will not have any authority to claim any intellectual property rights, title, or interest in its
              contents. The contents include and are not limited to the design, layout, look, and graphics.
            </li>
            <li>
              You acknowledge that unauthorized use of the Platform and/or the Services may lead to action
              against you as per these Terms of Use and/or applicable laws.
            </li>
            <li>You agree to pay us the charges associated with availing the Services.</li>
            <li>
              You agree not to use the Platform and/or Services for any purpose that is unlawful, illegal or
              forbidden by these Terms, or Indian or local laws that might apply to you.
            </li>
            <li>
              You agree and acknowledge that the website and the Services may contain links to other third-party
              websites. On accessing these links, you will be governed by the terms of use, privacy policy, and
              such other policies of such third-party websites. These links are provided for your convenience
              to provide further information.
            </li>
            <li>
              You understand that upon initiating a transaction for availing the Services you are entering into a
              legally binding and enforceable contract with the Platform Owner for the Services.
            </li>
            <li>
              You shall indemnify and hold harmless Platform Owner, its affiliates, group companies (as
              applicable) and their respective officers, directors, agents, and employees, from any claim or
              demand, or actions including reasonable attorneys’ fees, made by any third party or penalty
              imposed due to or arising out of Your breach of this Terms of Use, Privacy Policy and other
              Policies, or Your violation of any law, rules or regulations or the rights (including infringement of
              intellectual property rights) of a third party.
            </li>
            <li>
              In no event will the Platform Owner be liable for any indirect, consequential, incidental, special
              or punitive damages, including without limitation damages for loss of profits or revenues,
              business interruption, loss of business opportunities, loss of data or loss of other economic
              interests, whether in contract, negligence, tort or otherwise, arising from the use of or inability
              to use the Services, however caused and whether arising in contract, tort, negligence, warranty
              or otherwise, exceed the amount paid by You for using the Services giving rise to the cause of
              action or Rupees One Hundred (Rs. 100) whichever is less.
            </li>
            <li>
              Notwithstanding anything contained in these Terms of Use, the parties shall not be liable for any
              failure to perform an obligation under these Terms if performance is prevented or delayed by a
              force majeure event.
            </li>
            <li>
              These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and
              construed in accordance with the laws of India.
            </li>
            <li>
              All disputes arising out of or in connection with these Terms shall be subject to the exclusive
              jurisdiction of the courts in Jhajjar, Haryana.
            </li>
            <li>
              All concerns or communications relating to these Terms must be communicated to us using the
              contact information provided on this website.
            </li>
          </ol>

          {/* Cancellation and Refund Policy */}
          <h3 className="text-xl font-semibold mt-8 mb-4">Cancellation and Refund Policy</h3>
          <p>
            This cancellation policy outlines how you can cancel or seek a refund for a product/service that you have
            purchased through the Platform. Under this policy:
          </p>
          <ol className="list-decimal ml-8 space-y-2">
            <li>
              Cancellations will only be considered if the request is made within 2 days of placing the order. However, cancellation requests may not be entertained if the orders have been communicated to such sellers/merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep.
            </li>
            <li>
              We do not accept cancellation requests for perishable items like flowers, eatables, etc. However, the refund/replacement can be made if the user establishes that the quality of the product delivered is not good.
            </li>
            <li>
              In case of receipt of damaged or defective items, please report to our customer service team. The request would be entertained once the seller/merchant listed on the Platform has checked and determined the same at its own end. This should be reported within 1 day of receipt of products. In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 1 day of receiving the product. The customer service team after looking into your complaint will take an appropriate decision.
            </li>
            <li>
              In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue to them.
            </li>
            <li>
              In case of any refunds approved by us, it will take 4 days for the refund to be processed to you.
            </li>
          </ol>

          {/* Returns Policy */}
          <h3 className="text-xl font-semibold mt-8 mb-4">Returns Policy</h3>
          <p>
            We offer refund/exchange within the first 2 days from the date of your purchase. If 2 days have passed since your purchase, you will not be offered a return, exchange, or refund of any kind. In order to become eligible for a return or an exchange:
          </p>
          <ol className="list-decimal ml-8 space-y-2">
            <li>The purchased item should be unused and in the same condition as you received it.</li>
            <li>The item must have original packaging.</li>
            <li>
              If the item that you purchased was on sale, then the item may not be eligible for a return/exchange.
            </li>
          </ol>
          <p>
            Further, only such items are replaced by us (based on an exchange request), if such items are found defective or damaged. If you need to place a return/exchange request for an eligible product/item (as applicable), you need to send us an email at <a href="mailto:ceo@krshak.com" className="text-blue-600 underline">ceo@krshak.com</a>.
          </p>
          <p>
            You agree that there may be a certain category of products/items that are exempted from returns or refunds. Such categories of the products would be identified to you at the time of purchase. For exchange/return accepted request(s) (as applicable), once your returned product/item is received and inspected by us, we will send you an email to notify you about receipt of the returned/exchanged product. Further, if the same has been approved after the quality check at our end, your request (i.e., return/exchange) will be processed in accordance with our policies.
          </p>

          {/* Shipping Policy */}
          <h3 className="text-xl font-semibold mt-8 mb-4">Shipping Policy</h3>
          <p>
            The orders for the user are shipped through registered domestic courier companies and/or speed post only. Orders are shipped within 2 to 5 days from the date of the order and/or payment or as per the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to courier company/post office norms. Platform Owner shall not be liable for any delay in delivery by the courier company/postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration. If there are any shipping costs levied by the seller or the Platform Owner (as the case be), the same is not refundable. For any issues in utilizing our services, you may contact our helpdesk on <a href="tel:9817247342" className="text-blue-600 underline">9817247342</a>.
          </p>
        </div>
      </div>
    </div>
  );
}


function AppContent() {
  const navigate = useNavigate();
  useFacebookPixel();
  const { user, logout, authToken } = useAuth();
  const [farmers, setFarmers] = useState([]);
  const [isPending, startTransition] = useTransition();

  

  const fetchFarmers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/farmers/`, {
        headers: { Authorization: `Token ${authToken}` },
      });
      setFarmers(response.data);
    } catch (error) {
      console.error('There was an error fetching the farmers', error);
    }
  };

  const addFarmer = async (farmer) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/farmers/`, farmer, {
        headers: { Authorization: `Token ${authToken}` },
      });
      fetchFarmers(); // Refresh the list of farmers after adding a new one
      navigate('/'); // Add this line to redirect after adding
    } catch (error) {
      console.error('There was an error adding the farmer', error);
    }
  };

  const refreshFarmers = async () => {
    fetchFarmers();
  };



  return (
    <div className="flex flex-col min-h-screen bg-gray-100 text-gray-900">
    <Header />
    <main className="flex-grow mt-16">
    <Suspense fallback={<div>Loading...</div>}>

    <Routes>
    <Route path="/" element={<MainContent/>} />
    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
    <Route path="/data-policy" element={<DeleteAccount/>} />
    <Route path="/login" element={<LoginForm />} />
    <Route path="/register" element={<RegisterForm />} />
    <Route path="/vendor-register" element={<VendorRegisterForm />} />
    <Route path="/add-farmer" element={<AddFarmerForm addFarmer={addFarmer} authToken={authToken} onRefresh={refreshFarmers} />} />
    <Route path="/add-farm" element={<AddFarmForm addFarmer={addFarmer} authToken={authToken} onRefresh={refreshFarmers} />} />
    <Route path="/farmer/:farmerId" element={<FarmerDetail authToken={authToken} onRefresh={refreshFarmers} />} />
    <Route path="/privacy-policy/" element={<PrivacyPolicy/>} />
    <Route path="/data-policy/" element={<DeleteAccount/>} />
    <Route path="/vdashboard" element={<VDashboard />} />
    <Route path="/vproducts" element={<VProductPage />} />
    <Route path="/vorders" element={<VOrderPage />} />
    <Route path="/retailer" element={<RetailerPage />} />
    <Route path="/dashboard/" element={<RMDashboard />} />
    <Route path="/callplan/" element={<CallPlanPage />} />
    <Route path="/farmers/" element={<ListPage />} />
    <Route path="/products/:id" element={<ProductDetail />} />
    <Route path="/vendor/:vendorId" element={<VendorPortfolio />} />
    <Route path="/brand/:brandId/products" element={<BrandPage />} />
    <Route path="/category/:categoryId/products" element={<CategoryPage />} />
    <Route path="/crop/:cropId/products" element={<CropPage />} />
    <Route path="/cart" element={<Cart />} />
    <Route path="/checkout" element={<CheckoutPage />} />
    <Route path="/payment/callback" element={<PaymentCallback />} />
    <Route path="/order-summary" element={<OrderSummaryPage />} />
    <Route path="/ecom" element={<EcomPage />} />

    {/* Define other routes here */}
  </Routes>
</Suspense>
    </main>
    <Footer />
  </div>
  
);
}
  
  

  function MainContent() {
    const { user } = useAuth(); // Assuming `useAuth` provides user info
    const navigate = useNavigate();
  
    // Redirect based on user type
    useEffect(() => {
      if (user) {
        
        switch (user.user_type) {
          case 'rm':
            navigate('/dashboard/');
            break;
          case 'retailer':
            navigate('/farmers/');           
            break;
          default:
            navigate('/');
            break;
        }
      }
    }, [user, navigate]);
  
    // Default content if not redirected
    return <Home />;
  }
  

  const ListPage = () => {
    const [isPending, startTransition] = useTransition(); // State for loading indicator
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { authToken, isAuthenticated, login, user, logout } = useAuth();
    const [farmers, setFarmers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [phoneContacts, setPhoneContacts] = useState([]);


    useEffect(() => {
      if (authToken) {
        setLoading(true);
        startTransition(() => {
                fetchFarmers().finally(() => setLoading(false));
            });        
      }
    }, [authToken]);

    const fetchFarmers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/farmers/`, {
          headers: { Authorization: `Token ${authToken}` },
        });
        setFarmers(response.data);
      } catch (error) {
        console.error('There was an error fetching the farmers', error);
      }
    };
  
  
    const addFarmer = async (farmer) => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/farmers/`, farmer, {
          headers: { Authorization: `Token ${authToken}` },
        });
        fetchFarmers(); // Refresh the list of farmers after adding a new one
        navigate('/'); // Add this line to redirect after adding
      } catch (error) {
        console.error('There was an error adding the farmer', error);
      }
    };
    
    
    
  
    const filteredFarmers = farmers.filter(farmer => 
      typeof farmer.phone === 'string' && farmer.phone.includes(searchTerm)
    );
  
    const handleSearchChange = async (e) => {
      const value = e.target.value;
      startTransition(() => {
        setSearchTerm(value);
        if (!value) {
            setPhoneContacts([]);
        } else {
            const filtered = filteredFarmers(farmers, value);
            if (!filtered.length) {
                fetchContacts(value);
            } else {
                setPhoneContacts(filtered);
            }
        }
    });
  
      const filteredFarmers = farmers.filter(farmer => farmer.phone.includes(value));
      if (filteredFarmers.length === 0) {
        try {
          const contacts = await navigator.contacts.select(['name', 'tel'], { multiple: true });
          const contactsWithPhone = contacts.map(contact => ({
            name: contact.name[0],
            phone: contact.tel[0]
          })).filter(contact => contact.phone.includes(value));
  
          setPhoneContacts(contactsWithPhone);
        } catch (err) {
          console.error('Error accessing contacts:', err);
        }
      } else {
        setPhoneContacts([]);
      }
    };
  
    const fetchContacts = async (value) => {
      try {
          const contacts = await navigator.contacts.select(['name', 'tel'], { multiple: true });
          startTransition(() => {
              setPhoneContacts(contacts.filter(c => c.tel[0].includes(value)));
          });
      } catch (error) {
          console.error('Error accessing contacts:', error);
      }
  };
  
    const addContactAsFarmer = (contact) => {
      navigate('/add-farmer', { state: { name: contact.name, phone: contact.phone } });
    };

    return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-lg p-6">
        <input
          type="text"
          placeholder="Search phone नंबर खोजे..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-4 mb-4 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <button
          onClick={() => navigate('/add-farmer')}
          className="w-full mb-4 py-2 px-4 bg-green-500 text-white text-sm font-medium rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50"
        >
          <div>
          {phoneContacts.map(contact => (
            <div key={contact.phone} className="flex justify-between items-center p-2 border-b border-gray-300">
              <span>{contact.name} - {contact.phone}</span>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                onClick={() => navigate('/add-farm', { state: { farmerName: contact.name, phone: contact.phone } })}
              >
                Add Farm
              </button>
            </div>
          ))}
        </div>
          Add Farmer - किसान जोड़े
        </button>
        <FarmerList farmers={filteredFarmers} />
        <button
          onClick={logout}
          className="mt-4 w-full py-2 px-4 bg-red-500 text-white text-sm font-medium rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
        >
          Logout
        </button>
      </div>
    </div>
  ) }

  
  

  

function App() {

  return (
    <AuthProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
