import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import reportWebVitals from './reportWebVitals';
import AuthProvider from './context/AuthContext'; // Adjust the path as necessary
import * as serviceWorkerRegistration from './ServiceWorkerRegistration'; // Ensure this exists


serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    console.log('Service Worker registered successfully:', registration);
  },
  onUpdate: (registration) => {
    console.log('Service Worker updated:', registration);
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

// Wrap the App component with BrowserRouter for routing support
root.render(
    <AuthProvider>
        <App />
    </AuthProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
