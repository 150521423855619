// components/common/SimpleProductCard.js
import React from 'react';

const SimpleProductCard = ({ product, onProductClick }) => {
  const packingSize = product.packing_sizes[0] || {};
  const originalPrice = parseFloat(packingSize.price || 0);
  const discountPrice = parseFloat(packingSize.discount_price || 0);

  return (
    <div
      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-col"
      onClick={() => onProductClick(product.id)}
    >
      {/* Image */}
      <div className="w-full h-40">
        <img
          src={
            product.media && product.media.length > 0
              ? product.media[0].display_image_url
              : 'https://via.placeholder.com/150'
          }
          alt={product.name || 'Product Image'}
          className="w-full h-full object-cover rounded-t-lg"
        />
      </div>

      {/* Product Info */}
      <div className="p-4 flex-grow flex flex-col">
        <h2 className="text-sm font-bold mb-1 text-gray-800">{product.name || 'Product Name'}</h2>
        <p className="text-xs text-gray-600 mb-1">
          Brand: {product.brand?.name || 'N/A'}
        </p>
        <p className="text-xs text-gray-600 mb-1">
          Technical: {product.chemical_names.join(', ') || 'N/A'}
        </p>
        {packingSize.size && (
          <p className="text-xs text-gray-600 mb-1">
            Size: {packingSize.size}
          </p>
        )}

        {/* Pricing Information */}
        <div className="mt-2">
          {discountPrice > 0 && discountPrice !== originalPrice ? (
            <div className="flex items-center space-x-2">
              <p className="text-xs text-red-500 line-through">
                ₹{originalPrice.toFixed(2)}
              </p>
              <p className="text-sm text-green-600 font-semibold">
                ₹{discountPrice.toFixed(2)}
              </p>
            </div>
          ) : (
            <p className="text-sm text-gray-800">
              ₹{originalPrice.toFixed(2)}
            </p>
          )}
          {discountPrice > 0 && discountPrice !== originalPrice && (
            <p className="text-xs text-gray-500 mt-1">
              You Save: ₹{(originalPrice - discountPrice).toFixed(2)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimpleProductCard;
