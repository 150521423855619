import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function VendorRegisterForm() {
  const [vendorData, setVendorData] = useState({
    shop_name: '',
    mobile_number: '',
    password: '',
    confirm_password: '',
    pincode: '',
    states: [],  // Expect state IDs here
    address: '',
    gst_number: '',
  });

  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/states/`);
        if (Array.isArray(response.data)) {
          setStates(response.data);
        } else {
          setErrorMessage('Unexpected data format. Please try again.');
        }      
      } catch (error) {
        console.error('Error fetching states:', error);
        setErrorMessage('Failed to load states. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setVendorData({ ...vendorData, [e.target.name]: e.target.value });
    setErrorMessage('');
  };

  const handleStateSelection = (e) => {
    const selectedStates = Array.from(e.target.selectedOptions, option => option.value);
    setVendorData({ ...vendorData, states: selectedStates });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');  // Reset error message

    if (vendorData.password !== vendorData.confirm_password) {
      setErrorMessage('Passwords do not match. Please try again.');
      return;
    }

    if (!vendorData.shop_name || !vendorData.mobile_number || !vendorData.pincode || !vendorData.address || !vendorData.gst_number || vendorData.states.length === 0) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/vregister/`, 
        {
        user: {
          shop_name: vendorData.shop_name,
          mobile_number: vendorData.mobile_number,
          password: vendorData.password,
          pincode: vendorData.pincode,
        },
        states: vendorData.states,  // Array of state IDs
        address: vendorData.address,
        gst_number: vendorData.gst_number,
      },
      {
        headers: {
          'Authorization': '', // Override the Authorization header
        },
      }
    );

      setIsLoading(false);
      navigate('/login');
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setErrorMessage(error.response.data.detail || 'Registration failed. Please try again.');
      } else {
        setErrorMessage('Network error. Please try again.');
      }
    }
  };

  return (
    <section className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 dark:bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
            Vendor Registration/ विक्रेता पंजीकरण
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="shop_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Shop Name/दुकान का नाम
              </label>
              <input
                id="shop_name"
                name="shop_name"
                type="text"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter shop name"
                value={vendorData.shop_name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="mobile_number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Mobile Number/आपका फ़ोन नम्बर
              </label>
              <input
                id="mobile_number"
                name="mobile_number"
                type="text"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter your mobile number"
                value={vendorData.mobile_number}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Create a password"
                value={vendorData.password}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Confirm Password
              </label>
              <input
                id="confirm_password"
                name="confirm_password"
                type="password"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Confirm your password"
                value={vendorData.confirm_password}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Pincode
              </label>
              <input
                id="pincode"
                name="pincode"
                type="text"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter your pincode"
                value={vendorData.pincode}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="states" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                State(s)/राज्य
              </label>
              <select
                id="states"
                name="states"
                multiple
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                value={vendorData.states}
                onChange={handleStateSelection}
              >
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Address/पता
              </label>
              <textarea
                id="address"
                name="address"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter address"
                value={vendorData.address}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="gst_number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                GST Number/जीएसटी नम्बर
              </label>
              <input
                id="gst_number"
                name="gst_number"
                type="text"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter GST number"
                value={vendorData.gst_number}
                onChange={handleChange}
              />
            </div>
            <button type="submit" disabled={isLoading} className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              {isLoading ? 'Registering...' : 'Register as Vendor'}
            </button>
          </form>
          {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
        </div>
      </div>
    </section>
  );
}

export default VendorRegisterForm;
